<script>
import {mapActions, mapState} from "vuex"

export default {
	name: "Snackbar",
	data() {
		return {
		}
	},
	computed: {
		...mapState("App", {
			snackbar: "snackbar",
			snackbarType: "snackbarType",
			snackbarMessage: "snackbarMessage",
			snackbarTimeout: "snackbarTimeout",
		}),
		model: {
			get() {
				return this.snackbar
			},
			set(value) {
				this.$store.dispatch("setSnackbar", value)
			},
		},
	},
	methods: {
		...mapActions("App", {setSnackbar: "setSnackbar"}),
	}
}
</script>

<template>
	<v-snackbar
		:value="snackbar"
		:top="true"
		:timeout="-1"
		:color="snackbarType"
		:multi-line="true"
	>
		<span style="white-space: pre-line;">{{ snackbarMessage }}</span>
		<template #action="{attrs}">
			<v-btn
				text
				rounded
				depressed
				v-bind="attrs"
				@click="setSnackbar(false)"
			>
				{{ $t("snackbar.close-btn") }}
			</v-btn>
		</template>
	</v-snackbar>
</template>